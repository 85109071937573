<template>
  <v-container
    id="importacoes-dados-historico"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-xml"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Importações INDQUAL</div>
          </template>

          <v-btn
            v-if="accessReleased('IMPORTACOES_INDQUAL_ADICIONAR')"
            id="btn-nova-importacao-dados"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('add')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Nova importação
          </v-btn>

          <v-text-field
            id="importacao-dados-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-importacao-dados"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="importacoes-dados-table"
            :headers="[
              {
                text: '#',
                value: 'id'
              },
              {
                text: 'Título',
                value: 'titulo'
              },
              {
                text: 'Empresa',
                value: 'empresa'
              },
              {
                text: 'Nº de arquivos',
                value: 'numero_arquivos'
              },
              {
                text: 'Status',
                value: 'status'
              },
              {
                sortable: false,
                text: 'Ações',
                value: 'actions',
                class: 'pl-4'
              }
            ]"
            :items="importacoes"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            :mobile-breakpoint="800"
            no-data-text="Nenhuma importação de dados encontrada"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | addLeadingZero }}
            </template>
            <template v-slot:[`item.numero_arquivos`]="{ item }">
              {{ item.numero_arquivos }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <general-status :status="item.status" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="accessReleased('IMPORTACOES_INDQUAL_LOGS')"
                id="logs-importacao-dados"
                min-width="0"
                class="px-1"
                fab
                icon
                x-small
                @click="$router.push(`logs/${item.id}`)"
              >
                <v-icon small> mdi-file-tree </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getImportacoes"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImportacoesDadosIndqualXmlService from '@/services/ImportacoesDadosIndqualXmlService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'ImportacoesDadosHistorico',
  mixins: [refreshDataMixins, profilePermissionsMixin],
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },
  data: () => ({
    importacoes: [],
    search: '',
    loading: false
  }),
  mounted() {
    this.refreshData(this.getImportacoes);
  },
  methods: {
    getImportacoes() {
      this.loading = true;
      ImportacoesDadosIndqualXmlService.index()
        .then((response) => {
          this.importacoes = response.data;
        })
        .catch((err) => {
          console.error('Erro: ', err);
          this.$toast.error('Erro ao buscar as importações.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .importacoes-dados-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .importacoes-dados-table table tr td:nth-child(7) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-importacao-dados {
    width: 235.86px !important;
  }
}
</style>
