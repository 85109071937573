var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"importacoes-dados-historico","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-xml","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Importações INDQUAL")])]},proxy:true}])},[(_vm.accessReleased('IMPORTACOES_INDQUAL_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-importacao-dados","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova importação ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-importacao-dados",staticStyle:{"max-width":"250px"},attrs:{"id":"importacao-dados-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"importacoes-dados-table",attrs:{"headers":[
            {
              text: '#',
              value: 'id'
            },
            {
              text: 'Título',
              value: 'titulo'
            },
            {
              text: 'Empresa',
              value: 'empresa'
            },
            {
              text: 'Nº de arquivos',
              value: 'numero_arquivos'
            },
            {
              text: 'Status',
              value: 'status'
            },
            {
              sortable: false,
              text: 'Ações',
              value: 'actions',
              class: 'pl-4'
            }
          ],"items":_vm.importacoes,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhuma importação de dados encontrada","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.numero_arquivos",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_arquivos)+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.accessReleased('IMPORTACOES_INDQUAL_LOGS'))?_c('v-btn',{staticClass:"px-1",attrs:{"id":"logs-importacao-dados","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("logs/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-tree ")])],1):_vm._e()]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getImportacoes}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }